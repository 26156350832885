<template>
  <div
    id="registration"
    :style="index ? 'background:#f5f5f5;top:46px;padding: 12px 15px;' : ''"
  >
    <van-nav-bar
      left-arrow
      @click-left="index = 0"
      v-show="index"
      :fixed="true"
    />
    <div class="tips" v-if="!index">
      <svg-icon icon-class="ts_icon" />
      <span
        >请按要求填写规范信息，店长资料由正品汇平台审核通过后，可以正式开业</span
      >
    </div>
    <van-form
      @submit="onSubmit"
      :scroll-to-error="true"
      :show-error-message="false"
      validate-trigger="onSubmit"
      v-show="index === 0"
    >
      <!-- :show-error-message="false" -->
      <h3>基本信息（<span>*</span>必填）</h3>
      <van-field
        v-model="actualName"
        name="actualName"
        label="店长姓名"
        placeholder="请输入真实姓名"
        input-align="right"
        :rules="[{ required: true, message: '请填写真实姓名' }]"
      />
      <van-field name="radio" label="有无实体门店" input-align="right">
        <template #input>
          <van-radio-group v-model="entityStore" direction="horizontal">
            <van-radio :name="true">有</van-radio>
            <van-radio :name="false">无</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <van-field
        name="radio"
        label="有无冷藏冷冻设备"
        input-align="right"
        label-width="auto"
      >
        <template #input>
          <van-radio-group v-model="refrigeration" direction="horizontal">
            <van-radio :name="true">有</van-radio>
            <van-radio :name="false">无</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <van-field
        v-model="getGoodsName"
        name="getGoodsName"
        label="社区自提点"
        placeholder="请输入社区自提点"
        input-align="right"
        :rules="[{ required: true, message: '请填写社区自提点' }]"
      />
      <van-field
        readonly
        name="area"
        :value="getGoodsAddress"
        label="详细地址"
        placeholder="请选择地址"
        input-align="right"
        @click-input="addressShow"
        :rules="[{ required: true, message: '' }]"
      >
        <template #right-icon
          ><van-loading
            v-if="locationShow"
            size="24"
            color="#1989fa" /><svg-icon
            @click="getCity"
            icon-class="address_icon2"
            v-else
        /></template>
      </van-field>
      <van-popup v-model="showArea" position="bottom">
        <van-area
          :area-list="areaList"
          @confirm="onConfirm"
          @cancel="showArea = false"
          :value="location.areaCode"
        />
      </van-popup>
      <van-popup
        position="bottom"
        v-model="showAddress"
        @click-overlay="showArea = true"
      >
        <div class="van-picker__toolbar">
          <button
            type="button"
            class="van-picker__cancel"
            @click="
              showArea = true;
              showAddress = false;
            "
          >
            取消
          </button>
          <button type="button" class="van-picker__confirm" @click="onAddress">
            确认
          </button>
        </div>
        <van-field
          v-model="Address2"
          label="详细地址"
          class="address"
          label-width="auto"
          input-align="right"
        >
          <template #input>
            <div style="display: flex; flex-direction: column; flex: 1">
              <p>{{ Address }}</p>
              <input
                type="text"
                style="border: none; text-align: end"
                ref="address"
                v-model="Address2"
              />
            </div>
          </template>
        </van-field>
      </van-popup>

      <van-field
        v-model="detailAddress"
        name="detailAddress"
        label="具体门牌号"
        placeholder="请输入具体门牌号"
        input-align="right"
        :rules="[{ required: true, message: '请输入具体门牌号' }]"
      />
      <van-field
        name="uploader"
        label="身份证照片"
        class="van-cell-pirce2"
        label-width="auto"
      >
        <template #input>
          <van-uploader v-model="frontId" max-count="1" :after-read="afterRead">
            <template #default>
              <div class="top">
                <svg-icon icon-class="id_card_zm" />
              </div>
              <div class="bottom">
                <img src="@/assets/images/camera_icon.png" alt="" />
                点击上传身份证正面
              </div>
            </template>
          </van-uploader>
          <van-uploader
            v-model="reverseId"
            max-count="1"
            :after-read="afterRead2"
          >
            <template #default>
              <div class="top">
                <svg-icon icon-class="id_card_fm" />
              </div>
              <div class="bottom">
                <img src="@/assets/images/camera_icon.png" alt="" />
                点击上传身份证反面
              </div>
            </template>
          </van-uploader>
        </template>
      </van-field>
      <van-field
        name="uploader"
        label="自提点照片（门头、场地照）"
        class="van-cell-pirce"
        label-width="auto"
      >
        <template #input>
          <van-uploader
            v-model="getGoodsPicture"
            max-count="1"
            :after-read="afterRead3"
          >
            <template #default>
              <div class="top">
                <svg-icon icon-class="ztd" />
              </div>
              <div class="bottom">
                <img src="@/assets/images/camera_icon.png" alt="" />
                点击上传自提点照片
              </div>
            </template>
          </van-uploader>
        </template>
      </van-field>
      <h3>工商信息（<span>*</span>必填）</h3>
      <van-field name="radio" label="有无营业执照" input-align="right">
        <template #input>
          <van-radio-group v-model="businessLicense" direction="horizontal">
            <van-radio :name="true">有</van-radio>
            <van-radio :name="false">无</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <transition name="van-fade">
        <div v-if="businessLicense">
          <van-field
            v-model="enterpriseName"
            name="enterpriseName"
            label="企业名称"
            placeholder="请输入企业名称"
            input-align="right"
            :rules="[{ required: true, message: '请填写企业名称' }]"
          />
          <van-field
            v-model="orgCode"
            name="orgCode"
            label="统一社会信用代码"
            placeholder="请输入统一社会信用代码"
            input-align="right"
            label-width="auto"
            :rules="[{ required: true, message: '请填写统一社会信用代码' }]"
          />
          <van-field
            name="uploader"
            label="营业执照图片"
            class="van-cell-pirce"
            label-width="auto"
          >
            <template #input>
              <van-uploader
                v-model="businessLicensePicture"
                max-count="1"
                :after-read="afterRead4"
              >
                <template #default>
                  <div class="top">
                    <svg-icon icon-class="yyzz" />
                  </div>
                  <div class="bottom">
                    <img src="@/assets/images/camera_icon.png" alt="" />
                    点击上传营业执照
                  </div>
                </template>
              </van-uploader>
            </template>
          </van-field>
        </div>
      </transition>
      <h3>银行卡信息（<span></span>非必填）</h3>
      <van-field
        v-model="bankCardType"
        name="bankCardType"
        label="银行卡类型"
        input-align="right"
        :rules="[{ required: true, message: '请填写银行卡类型' }]"
        class="van-cell-bank-card"
        readonly
      >
        <template #input>
          <p><span>*</span> 默认卡类型为储蓄卡</p>
          <p>{{ bankCardType }}</p>
        </template>
      </van-field>
      <van-field
        v-model="bankName"
        name="bankName"
        label="开户银行"
        placeholder="请输入开户银行"
        input-align="right"
      />
      <!-- :rules="[{ required: true, message: '请填写开户银行' }]" -->
      <van-field
        v-model="cardHolderName"
        name="cardHolderName"
        label="持卡人"
        placeholder="请输入持卡人"
        input-align="right"
      />
      <!-- :rules="[{ required: true, message: '请填写持卡人' }]" -->
      <van-field
        v-model="cardCode"
        name="cardCode"
        label="银行卡号"
        placeholder="请输入银行卡号"
        input-align="right"
        type="digit"
      />
      <!-- :rules="[{ required: true, message: '请填写银行卡号' }]" -->
      <div class="bottom_btn van-hairline--top">
        <van-button round block type="info" native-type="submit" color="#009a4d"
          >提交修改</van-button
        >
      </div>
    </van-form>

    <div class="pda" v-show="index">
      <div class="goods">
        <img src="@/assets/images/PDA.png" alt="" />
        <dl>
          <dt>SUMI商米PDA手持终端</dt>
          <dd>
            <p>品牌名称：<span>商米</span></p>
            <p>型号：<span>202201</span></p>
            <p v-if="buy">每月租金：<span class="price">￥100.00</span></p>
            <p v-else>店长价：<span class="price">￥3000.00</span></p>
          </dd>
        </dl>
      </div>
      <van-field label="选择获取方式" input-align="right" label-width="auto">
        <template #input>
          <van-radio-group v-model="buy" direction="horizontal">
            <van-radio :name="0">直接购买</van-radio>
            <van-radio :name="1">租聘PDA</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <ul>
        <h5>说明：</h5>
        <li>1、选择获取方式后，平台商务将于3个工作日内进行对接；</li>
        <li>2、团长如需退还PDA，请联系平台商务申请退还。</li>
      </ul>
      <div class="bottom_btn">
        <van-button round block @click="updataUser" color="#009A4D"
          >完成申请</van-button
        >
      </div>
    </div>

    <baidu-map @ready="mapReady"> </baidu-map>
  </div>
</template>

<script>
var _ = require("lodash");
import { areaList } from "@vant/area-data";
import { Toast } from "vant";
import { mapState } from "vuex";
export default {
  name: "registration",
  computed: {
    ...mapState("user", ["userInfo"]),
  },
  data() {
    return {
      actualName: "", // 店长姓名
      entityStore: true, // 有无实体门店
      refrigeration: false, // 有无冷藏冷冻设备
      getGoodsName: "", // 社区自提点
      getGoodsAddress: "", // 详细地址
      detailAddress: "", // 具体门牌号
      frontId: [], // 身份证照片正面
      reverseId: [], // 身份证照片反面
      getGoodsPicture: [], // 自提点照片（门头、场地照）
      businessLicense: true, // 有无营业执照
      enterpriseName: "", // 企业名称
      orgCode: "", // 统一社会信用代码
      businessLicensePicture: [], // 营业执照图片
      bankCardType: "储蓄卡", // 银行卡类型
      bankName: "", // 开户银行
      cardHolderName: "", // 持卡人
      cardCode: "", // 银行卡号

      Address: "",
      Address2: "",
      showArea: false,
      showAddress: false,
      areaList,

      index: 0,
      buy: 0,
      show: false,
      //定位信息
      location: {
        lat: "", // 纬度
        lng: "", // 经度
        province: "", // 省
        city: "", // 市
        district: "", // 区
        street: "", //街道
        streetNumber: "",
        areaCode: "",
      },
      locationShow: false,
    };
  },
  // watch: {
  //   frontId(val, oldVal) {
  //     console.log(val[0]);
  // 		this.file(val[0])
  //   },
  // },
  created() {},
  methods: {
    onSubmit(values) {
      if (!this.frontId.length) {
        Toast.fail("请上传身份证正面照片");
      } else if (!this.reverseId.length) {
        Toast.fail("请上传身份证反面照片");
      } else if (!this.getGoodsPicture.length) {
        Toast.fail("请上传自提点照片（门头、场地照）");
      } else if (this.businessLicense && !this.businessLicensePicture.length) {
        Toast.fail("请上传营业执照图片");
      } else {
        let _this = this;
        this.myGeo.getPoint(this.getGoodsAddress, function (point) {
          if (point) {
            _this.location.lat = point.lat;
            _this.location.lng = point.lng;
          }
        });
        this.index++;
      }
    },
    onConfirm(values) {
      console.log(values);
      // this.getGoodsAddress = values
      //   .filter((item) => !!item)
      //   .map((item) => item.name)
      //   .join("/");
      let val = values.filter((item) => !!item).map((item) => item.name);
      if (val[0] === val[1]) {
        this.Address = val[0] + val[2];
      } else {
        this.Address = val.join("");
      }

      this.showArea = false;
      this.showAddress = true;
      this.$nextTick(function () {
        this.$refs.address.focus();
      });
      // console.log(this.getGoodsAddress);
    },
    onAddress() {
      this.getGoodsAddress = this.Address + this.Address2;
      window.scrollTo(0, 0);
      this.showAddress = false;
    },
    async updataUser() {
      // console.log(this.businessLicensePicture);
      //获取经纬度
      let object = {
        id: this.userInfo.id,
        userDetailEntity: {
          frontId: this.frontId[0].url, //身份证前照
          reverseId: this.reverseId[0].url, //身份证后照
          entityStore: this.entityStore, //有无实体门店
          refrigeration: this.refrigeration, //冷藏设备
          getGoodsName: this.getGoodsName, //自提点名称
          getGoodsAddress: this.getGoodsAddress + this.detailAddress, //自提点地址
          detailAddress: this.Address2 + this.detailAddress, //具体门牌号
          getGoodsPicture: this.getGoodsPicture[0].url, //自提点门头照
          businessLicense: this.businessLicense, //有无营业执照
          enterpriseName: this.enterpriseName, //企业名称
          pda: this.buy,
          orgCode: this.orgCode, //统一社会信用代码
          businessLicensePicture:
            this.businessLicensePicture.length == 0
              ? ""
              : this.businessLicensePicture[0].url, //营业执照图片
          bankCardType: this.bankCardType, //银行卡类型
          bankName: this.bankName, //开户银行
          cardHolderName: this.cardHolderName, //持卡人
          cardCode: this.cardCode, //银行卡号

          id: this.userInfo.userDetailEntity.id,
          idNumber: this.userInfo.userDetailEntity.idNumber, //身份证号
          profilePicture: this.userInfo.userDetailEntity.profilePicture, //头像
          userId: this.userInfo.userDetailEntity.userId,

          latitudeValue: this.location.lat, //地址纬坐标、缺省为0
          longitudeValue: this.location.lng, //地址经坐标、缺省为0
        },
        userEntity: {
          actualName: this.actualName, //真实姓名
          id: this.userInfo.userEntity.id, //
          name: this.userInfo.userEntity.name, //名称
          password: "", //密码
          telNum: this.userInfo.userEntity.telNum, //手机号
          userType: this.userInfo.userEntity.userType, //用户类型
          username: this.userInfo.userEntity.username, //用户名
        },
      };

      await this.$API.login
        .updateUser({ object })
        .then((res) => {
          if (res.data.code === "SUCCESS") {
            this.Toast.success("提交成功");
            this.$router.replace({
              path: "applicationStatus",
              query: {
                PDA: this.$route.query.PDA,
              },
            });
            // this.close();
          } else {
            //失败
            Toast.fail(res.data.msg);
            // res.data.msg
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    mapReady({ BMap, map }) {
      this.geolocation = new BMap.Geolocation();
      this.myGeo = new BMap.Geocoder();
    },
    addressShow() {
      this.showArea = true;
      _.forEach(this.areaList.city_list, (o, c) => {
        if (o == this.location.city) {
          let cityId = String(_.take(c, 2));
          _.forEach(this.areaList.county_list, (i, a) => {
            if (i == this.location.district) {
              let districtId = String(_.take(a, 2));
              if (cityId == districtId) {
                this.location.areaCode = a;
              }
            }
          });
        }
      });

      console.log(this.location);
    },
    getCity() {
      let _this = this;
      _this.locationShow = true;
      _this.geolocation.getCurrentPosition(function (r) {
        if (this.getStatus() == BMAP_STATUS_SUCCESS) {
          _this.locationShow = false;
          if (r.accuracy == null) {
            alert("您未开启手机定位权限");
            //用户决绝地理位置授权
            return;
          } else {
            const myGeo = new BMap.Geocoder();
            myGeo.getLocation(
              new BMap.Point(r.point.lng, r.point.lat),
              (data) => {
                console.log(data);
                if (data.addressComponents) {
                  const result = data.addressComponents;
                  _this.location = {
                    lat: r.point.lat, // 纬度
                    lng: r.point.lng, // 经度
                    province: result.province || "", // 省
                    city: result.city || "", // 市
                    district: result.district || "", // 区
                    street: result.street || "", //街道
                    streetNumber: result.streetNumber || "",
                    areaCode: "",
                  };

                  _this.Address =
                    _this.location.province +
                    _this.location.city +
                    _this.location.district;
                  _this.Address2 =
                    _this.location.street +
                      _this.location.streetNumber +
                      data.business || "";
                  // alert(this.getStatus());
                  _this.getGoodsAddress = _this.Address + _this.Address2;
                }
              }
            );
          }
        }
      });
    },
    afterRead(file) {
      var formdata = new FormData();
      formdata.append("file", file.file);
      this.$API.file
        .imageUpload(formdata)
        .then((res) => {
          console.log(res);
          this.frontId[0].url = res.data.data;
        })
        .catch((error) => {
          console.log(error);
          Toast.fail("图片上传失败");
        });
    },
    afterRead2(file) {
      var formdata = new FormData();
      formdata.append("file", file.file);
      this.$API.file
        .imageUpload(formdata)
        .then((res) => {
          console.log(res);
          this.reverseId[0].url = res.data.data;
        })
        .catch((error) => {
          console.log(error);
          Toast.fail("图片上传失败");
        });
    },
    afterRead3(file) {
      var formdata = new FormData();
      formdata.append("file", file.file);
      this.$API.file
        .imageUpload(formdata)
        .then((res) => {
          console.log(res);
          this.getGoodsPicture[0].url = res.data.data;
        })
        .catch((error) => {
          console.log(error);
          Toast.fail("图片上传失败");
        });
    },
    afterRead4(file) {
      var formdata = new FormData();
      formdata.append("file", file.file);
      this.$API.file
        .imageUpload(formdata)
        .then((res) => {
          console.log(res);
          this.businessLicensePicture[0].url = res.data.data;
        })
        .catch((error) => {
          console.log(error);
          Toast.fail("图片上传失败");
        });
    },
  },
};
</script>

<style lang="scss" scoped>
#registration {
  padding-top: 0;
  background: #fff;
  position: absolute;
  top: 0;
  width: 100%;
  bottom: 50px;
  overflow-y: scroll;
  ::v-deep .van-nav-bar__left .van-icon {
    font-size: 20px;
    color: #333;
  }
  .tips {
    // position: absolute;
    // left: 0;
    // right: 0;
    padding: 10px 15px;
    background: #f5f5f5;
    color: #f99c10;
    font-size: 12px;
    display: flex;
    align-items: center;
    .svg-icon {
      font-size: 16px;
      margin-right: 8px;
    }
  }
  h3 {
    font-size: 14px;
    margin-top: 16px;
    span {
      color: red;
    }
  }
  h3:nth-child(1) {
    margin-top: 0;
  }
  .van-form {
    padding: 15px;
  }
  .bottom_btn {
    position: fixed;
    bottom: 0;
    background: #fff;
    left: 0;
    right: 0;
    max-width: 500px;
    margin: 0 auto;
    padding: 3px 15px;
  }
  ::v-deep .van-cell {
    padding: 16px 0;
    .van-cell__title {
      color: #333;
    }
    .van-cell__value .van-field__right-icon {
      padding: 0 16px;
      font-size: 20px;
    }
    .van-radio__icon {
      padding-top: 1px;
      .van-icon {
        width: 18px;
        height: 18px;
      }
    }
    .van-radio__icon--checked {
      position: relative;
      .van-icon {
        border-color: #009a4d;
        background: #fff;
      }

      .van-icon-success:before {
        content: "";
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: #009a4d;
        position: absolute;
        left: 50%;
        top: 50%;
        margin-top: -5px;
        margin-left: -5px;
      }
    }
  }
  .van-cell-pirce,
  .van-cell-pirce2 {
    display: block;
    .van-uploader {
      flex: 1;
    }
  }
  .van-cell::after {
    left: 0;
    right: 0;
  }

  .address {
    padding: 15px;
  }

  .pda {
    background: #fff;
    font-size: 14px;
    padding: 18px 12px;
    // width: 100%;
    height: 100%;
    border-radius: 5px;
    overflow: scroll;
    .goods {
      display: flex;
      img {
        width: 120px;
        height: 120px;
        background: #fff;
        margin-right: 16px;
        border: 1px solid #dddddd;
        padding: 10px;
      }
      dl {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        dd {
          p {
            margin-top: 6px;
            font-size: 12px;
            color: #999999;
            span {
              color: #666666;
            }
            .price {
              font-size: 14px;
            }
          }
        }
      }
    }
    ::v-deep .van-cell {
      padding: 50px 0;
      display: block;
      .van-cell__title {
        color: #333;
      }
      .van-cell__value .van-radio-group {
        width: 100%;
        justify-content: space-evenly;
        margin-top: 20px;
      }

      .van-radio__icon {
        padding-top: 1px;
        .van-icon {
          width: 18px;
          height: 18px;
        }
      }
      .van-radio__icon--checked {
        position: relative;
        .van-icon {
          border-color: #009a4d;
          background: #fff;
        }
        .van-icon-success:before {
          content: "";
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background: #009a4d;
          position: absolute;
          left: 50%;
          top: 50%;
          margin-top: -5px;
          margin-left: -5px;
        }
      }
    }
    .van-cell::after {
      display: none;
    }
    ul {
      h5 {
        font-size: 14px;
        color: #333333;
        margin-bottom: 8px;
      }
      li {
        font-size: 12px;
        line-height: 20px;
        color: #999999;
      }
    }
    // .van-button {
    // }
  }
}
</style>

<style lang="scss">
.van-cell-pirce2 {
  .van-field__control {
    .van-uploader:nth-child(1) {
      padding-right: 7.5px;
    }
    .van-uploader:nth-child(2) {
      padding-left: 7.5px;
    }
    .van-uploader__wrapper {
      display: block;
    }
    .van-uploader {
      .top {
        padding: 10px 0;
        font-size: 70px;
      }
    }
  }
}
.van-cell-pirce {
  .van-uploader__wrapper {
    padding: 0 18px;
  }
  .van-uploader__input-wrapper {
    flex: 1;
    .top {
      font-size: 72px;
      padding: 50px 0;
    }
  }
}
.van-cell-pirce,
.van-cell-pirce2 {
  .van-uploader__preview {
    flex: 1;
    margin: 0;
    .van-image {
      width: auto;
      height: auto;
    }
  }
  .van-cell__value {
    margin-top: 16px;
    .van-uploader {
      .top {
        border-radius: 5px 5px 0 0;
        border: 1px dashed #d3d3d3;
        border-bottom: none;
        text-align: center;
      }
      .bottom {
        line-height: 20px;
        font-size: 14px;
        color: #ffffff;
        padding: 5px 0;
        border-radius: 0 0 5px 5px;
        background: #3e93fe;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 16px;
          margin-right: 4px;
        }
      }
    }
  }
}

.van-cell-bank-card {
  .van-field__control {
    justify-content: space-between;
    p:nth-child(1) {
      color: #999999;
      span {
        color: #f61423;
      }
    }
    // p:nth-child(2) {
    // }
  }
}
</style>
